exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-absence-management-software-js": () => import("./../../../src/pages/absence-management-software.js" /* webpackChunkName: "component---src-pages-absence-management-software-js" */),
  "component---src-pages-absence-policy-template-js": () => import("./../../../src/pages/absence-policy-template.js" /* webpackChunkName: "component---src-pages-absence-policy-template-js" */),
  "component---src-pages-annual-leave-policy-template-js": () => import("./../../../src/pages/annual-leave-policy-template.js" /* webpackChunkName: "component---src-pages-annual-leave-policy-template-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-discounts-js": () => import("./../../../src/pages/discounts.js" /* webpackChunkName: "component---src-pages-discounts-js" */),
  "component---src-pages-features-calendar-connections-js": () => import("./../../../src/pages/features/calendar-connections.js" /* webpackChunkName: "component---src-pages-features-calendar-connections-js" */),
  "component---src-pages-features-email-reports-js": () => import("./../../../src/pages/features/email-reports.js" /* webpackChunkName: "component---src-pages-features-email-reports-js" */),
  "component---src-pages-features-features-js": () => import("./../../../src/pages/features/features.js" /* webpackChunkName: "component---src-pages-features-features-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-features-leave-types-js": () => import("./../../../src/pages/features/leave-types.js" /* webpackChunkName: "component---src-pages-features-leave-types-js" */),
  "component---src-pages-features-slack-tools-js": () => import("./../../../src/pages/features/slack-tools.js" /* webpackChunkName: "component---src-pages-features-slack-tools-js" */),
  "component---src-pages-features-timetastic-pro-js": () => import("./../../../src/pages/features/timetastic-pro.js" /* webpackChunkName: "component---src-pages-features-timetastic-pro-js" */),
  "component---src-pages-holiday-calculator-js": () => import("./../../../src/pages/holiday-calculator.js" /* webpackChunkName: "component---src-pages-holiday-calculator-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-mdx-slug-js": () => import("./../../../src/pages/legal/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-legal-mdx-slug-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-slack-js": () => import("./../../../src/pages/slack.js" /* webpackChunkName: "component---src-pages-slack-js" */),
  "component---src-pages-staff-leave-planner-js": () => import("./../../../src/pages/staff-leave-planner.js" /* webpackChunkName: "component---src-pages-staff-leave-planner-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

